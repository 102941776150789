import './footer.css'



const Footer = (screen_type) => {
    
    let footer = ''

    if(screen_type === 'M'){
        footer = <div id="footnote-m" class="footnote-m" >
        <div className='footnote-m-top'>
            <span>Carraighill is an SEC Registered Investment Adviser (CRD No. 290703), offering research on select European, UK and emerging market financial institutions to professional investors. All investments carry a certain risk, and there is no assurance that investments made on the basis of our research will provide positive performance over any period. This communication is for informational purposes only. It does not and is not intended to constitute investment advice or an offer or solicitation for the purchase or sale of any financial instrument or as an official confirmation of any transaction. All market prices, data and other information are not warranted as to completeness or accuracy and are subject to change without notice.</span>

        </div>

        <div class="footer-divider-m">
          <svg class="footer-line-m" viewBox="10 5 105 5" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="5" x2="100" y2="5"/>
          </svg>
        </div>

        

        <div className='footnote-m-bottom'>
       
          <span>
            <p>© 2021 Carraighill Capital Ltd. Registered in Ireland. No. 529193.</p>
            <p>Eagle House, 5 Marine Road Dún Laoghaire, Co. Dublin, Ireland</p>
            <p>Contact: admin@carraighill.com +44(0) 7490221792</p>
            <a href='https://carraighill.com/privacy'>Privacy & Cookies</a>
            <a href='https://carraighill.com/terms-conditions'>Terms & Conditions</a>
            <a href='https://carraighill.com/disclaimer'>Disclaimer</a>
            <a href='https://carraighill.com/wp-content/themes/wp-carraighill-theme/public/assets/Carraighill-GDPR-2020.pdf'>GDPR Policy (PDF) </a>
          </span>


        </div>
                


          

      </div>
    } else {
        footer = <div id="footnote" class="footnote" >
        <div className='top-left'>

        </div>
        <div className='top-right'>
          
        </div>
        {/* <hr class='divider'></hr> */}
        <div class="footer-divider">
          <svg class="footer-line" viewBox="10 5 105 5" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="5" x2="100" y2="5"/>
          </svg>
        </div>

        <div className='left'>
          <span>Carraighill is an SEC Registered Investment Adviser (CRD No. 290703), offering research on select European, UK and emerging market financial institutions to professional investors. All investments carry a certain risk, and there is no assurance that investments made on the basis of our research will provide positive performance over any period. This communication is for informational purposes only. It does not and is not intended to constitute investment advice or an offer or solicitation for the purchase or sale of any financial instrument or as an official confirmation of any transaction. All market prices, data and other information are not warranted as to completeness or accuracy and are subject to change without notice.</span>

        </div>
        <div className='right'>
          
        <div>
            <div>
              
              
            </div>
          </div>
          <span>
            <p>© 2021 Carraighill Capital Ltd. Registered in Ireland. No. 529193.</p>
            <p>Eagle House, 5 Marine Road Dún Laoghaire, Co. Dublin, Ireland</p>
            <p>Contact: admin@carraighill.com +44(0) 7490221792</p>
            <a href='https://carraighill.com/privacy'>Privacy & Cookies</a>
            <a href='https://carraighill.com/terms-conditions'>Terms & Conditions</a>
            <a href='https://carraighill.com/disclaimer'>Disclaimer</a>
            <a href='https://carraighill.com/wp-content/themes/wp-carraighill-theme/public/assets/Carraighill-GDPR-2020.pdf'>GDPR Policy (PDF) </a>
          </span>


        </div>

          

      </div>
    }

    return(
        footer
    );

}

export default Footer;
import './sample.css'
import genHexagon from '../App.js'
import Request_form from './request_form.js'
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import axios from 'axios';




let api_endpoint = 'http://api.carraighill.eu:3003/api/sample'

async function showform() {
    
    // document.documentElement.style.setProperty('--form-width', '50%');
    // await new Promise(resolve => setTimeout(resolve, 250));
    document.documentElement.style.setProperty('--form-display', 'block');
  }

async function closeform() {
    // document.documentElement.style.setProperty('--form-width', '0');
    // await new Promise(resolve => setTimeout(resolve, 260));
    document.documentElement.style.setProperty('--form-display', 'none');

  }


const sample_div = () => {

    let block  = 
    <div class="sample_container">
        <div class="left_pane">
            <div class="sample_img_div">
                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/uk-banks-top-line-drivers_200.png"  alt='something'></img>
            </div>
            <div class="download_btn">
                <button onClick={() => showform()}>Download</button>
            </div>    
        </div>
        <div class="right_pane">
            
            { Request_form(1) }

        </div>
    </div>

    return(block)

}
 


const Sample = (screen_type) => {
  
  
    let sample = ''
    // const report = 'test-09'
    // const [name, setName] = useState('');
    // const [company, setCompany] = useState('');
    // const [email, setEmail] = useState('');

    // const handleSubmit = async (e, sample_id) => {
    //     e.preventDefault();
    //     try {
    //       const response = await axios.post(api_endpoint, {
    //         report,
    //         name,
    //         company,
    //         email,
    //       },
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'Accept': '*/*',
    //           'Access-Control-Allow-Origin': '*',
    //         },});
          
    //       setName('');
    //       setCompany('');
    //       setEmail('');
    //     } catch (error) {
    //       console.error('Error submitting form:', error);
    //     }

    //     if (sample_id === 1) {
    //         window.open("https://cdvp-open.s3.eu-west-1.amazonaws.com/UK+Bankx+March+2022.pdf", "_blank");
    //     }
    //   };

    // if (screen_type === 'M') {
    //     sample_turkish = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/Turkish_mobile_2.png'
    //     sample_uk = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/UK.png'
    //     sample = <div>
    //     <div class="sample-title">
    //     <h1>Sample Reports</h1>
    //     </div>
    
    //     <div id="corousel" class="corousel">
    //     <Swiper
    //         // install Swiper modules
    //         className='swiper-resized-400'
    //         modules={[Navigation, Pagination, Scrollbar, A11y]}
    //         spaceBetween={50}
    //         slidesPerView={1}
    //         navigation
    //         pagination={{ clickable: true }}
    //         // scrollbar={{ draggable: true }}
    //         // onSwiper={(swiper) => console.log(swiper)}
    //         onSlideChange={() => closeform()}
    //     >
    //         <SwiperSlide>
    //         {/* <h1>UK Banks: Top-Line Drivers</h1> */}
    //         <img src={sample_uk}  alt='something'></img>
    //         <button onClick={() => showform()}>Download</button>
    //             { Request_form }
    //         </SwiperSlide>
    //         <SwiperSlide>
    //             <div class="sample_art">

    //             </div>
    //         {/* <img src={sample_turkish} alt='something'></img> */}
    //         <button onClick={() => showform()}>Download</button>
    //         <div class="form">
    //             <div class="close" onClick={() => closeform()}>
    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                
    //             </div>
    //             <div class="container-text">
    //             <h3>Carraighill provides investment insights and recommendations to clients. All of the reports available here are dated and no longer constitute investment advice.</h3>
    //             <input type="name" placeholder="Full Name"></input>
    //             <input type="company" placeholder="Company"></input>
    //             <input type="email" placeholder="Email address"></input>
    //             <button onClick={()=> window.open("https://cdvp-open.s3.eu-west-1.amazonaws.com/Dissecting+Turkey+4.pdf", "_blank")} target="_blank">Submit</button>
                
    //             </div>
                

    //         </div>
    //         </SwiperSlide>
    //         <SwiperSlide>
    //         <img src='https://cdvp-open.s3.eu-west-1.amazonaws.com/The+Matrix+Brazil.png' alt='something'></img>
    //         <button onClick={() => showform()}>Download</button>
    //         <div class="form">
    //             <div class="close" onClick={() => closeform()}>
    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                
    //             </div>
    //             <div class="container-text">
    //             <h3>Carraighill provides investment insights and recommendations to clients. All of the reports available here are dated and no longer constitute investment advice.</h3>
    //             <input type="name" placeholder="Full Name"></input>
    //             <input type="company" placeholder="Company"></input>
    //             <input type="email" placeholder="Email address"></input>
    //             <button onClick={()=> window.open("https://cdvp-open.s3.eu-west-1.amazonaws.com/TheMatrix-Brazil.pdf", "_blank")} target="_blank">Submit</button>
                
    //             </div>
                

    //         </div>
    //         </SwiperSlide>
            
    //     </Swiper>


    //     </div>
    // </div>
    // }
    // else {
        var sample_turkish = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/Dissecting+Turkey+4.png'
        var sample_uk = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/UK+Banks+Top+Line+Drivers.png'

        // var img1 = "https://cdvp-open.s3.eu-west-1.amazonaws.com/uk-banks-top-line-drivers_200.png"

        let sample_div1 = sample_div()


        sample = 
        <div>
            <div class="sample-title">
            <h1>Sample Reports</h1>
            </div>
        
            <div id="corousel" class="corousel">
            <Swiper
                // install Swiper modules
                className='swiper-resized-400'
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => closeform()}
            >
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/uk-banks-top-line-drivers_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>
                                                      
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>UK Banks</h2>
                                <h3>Top-line Drivers </h3>
                            </div>
                            
                            
                            
                            { Request_form(1) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/7lyPuhXMKaJCVUR50JTDjkTfEOiQqeOi9cDm8XBk_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>
                                                      
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Adyen</h2>
                                <h3>Customer Deep Dive - Vulnerable to Lower Discretionary Spend</h3>
                            </div>
                            
                            
                            
                            { Request_form(2) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/global-inflation-rates_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>
                                                      
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Global Inflation & Rates</h2>
                                <h3>March 2022 Edition</h3>
                            </div>
                            
                            
                            
                            { Request_form(3) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/nexi-a-european-payments-consolidator_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>
                                                      
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Nexi</h2>
                                <h3>A European Payments Consolidator</h3>
                            </div>
                            
                            
                            
                            { Request_form(4) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/LWdEWxFk9qphDpCWTRrE5qIdq8o2eVpcj4raneGI_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Bank of Ireland</h2>
                                <h3>Low-hanging Fruit (4x P/E 2022f)</h3>
                            </div>
                            
                            { Request_form(5) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/the-matrix-brazil-ripe-for-short-opportunities_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>The Matrix: Brazil</h2>
                                <h3>Ripe for Short Opportunities</h3>
                            </div>
                            
                            { Request_form(6) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/british-land-our-view-remains-unchanged_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>British Land</h2>
                                <h3>Our View Remains Unchanged</h3>
                            </div>
                            
                            { Request_form(7) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/dissecting-turkey-4_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Dissecting Turkey 4</h2>
                                <h3></h3>
                            </div>
                            
                            { Request_form(8) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/brazilian-banks-analysis_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Brazilian Banks Analysis</h2>
                                <h3>Our Latest Thoughts and Fundamental Forecasts | February 2022 Edition</h3>
                            </div>
                            
                            { Request_form(9) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/italian-banks_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Italian Financials</h2>
                                <h3>Assessing Rising BTP Risks</h3>
                            </div>
                            
                            { Request_form(10) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/european-banks-analysis_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>European Banks Analysis</h2>
                                <h3>Our Latest Thoughts and Fundamental Forecasts | April 2022 Edition</h3>
                            </div>
                            
                            { Request_form(11) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/ecb_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>ECB</h2>
                                <h3>Clear Rates Lift Off = Long European Banks</h3>
                            </div>
                            
                            { Request_form(12) }

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/aibboi_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>AIB & BOI</h2>
                                <h3>Significant Top-Line Upgrades</h3>
                            </div>
                            
                            { Request_form(13) }

                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/rising_net_worth_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Rising Net Worth</h2>
                                <h3>Implications Of The Pandemic On European Banks & Discretionary Spend</h3>
                            </div>
                            
                            { Request_form(14) }

                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div class="sample_container">
                        <div class="left_pane">
                            <div class="sample_img_div">
                                <img class="sample_image" src="https://cdvp-open.s3.eu-west-1.amazonaws.com/emerging-markets-turkey-will-be-the-greatest-crisis-of-them-all_200.png"  alt='something'></img>
                            </div>
                            <div class="download_btn">
                                <button onClick={() => showform()}>Download</button>
                            </div>                    
                                
                        </div>
                        <div class="right_pane">
                            <div class="report_title">
                                <h2>Emerging Markets</h2>
                                <h3>Turkey Will Be the Greatest Crisis of Them All</h3>
                            </div>
                            
                            { Request_form(15) }

                        </div>
                    </div>
                </SwiperSlide>
                

                               
            </Swiper>


            </div>
        </div>
    // }

    return(
        sample
    );

}

export default Sample;
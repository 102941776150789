import './top_menu.css';
import logo from './logo.svg';

const Top_menu = (screen_type) => {
    let top_menu = ''

    if (screen_type === 'M') {
        top_menu = <div id="menu">
        <div class="logo-m">
          <img src={logo} href="#" className="app-logo" alt="logo" />
        </div>
        <div class="login-m">
          <span>
            <a href="https://www.app.carraighill.com">Login</a>
          </span>
          

        </div>
        

      </div>

    }
    else {
        top_menu = <div id="menu">
        <div class="logo">
          <img src={logo} href="#" className="app-logo" alt="logo" />
        </div>
        <div class="login">
          <span>
            <a href="https://www.app.carraighill.com">Client Login</a>
          </span>
          

        </div>
        

      </div>
    }

    return (
        top_menu
    );



}


export default Top_menu;
import './request_form.css'
import React, { useState } from 'react';
import axios from 'axios';

async function closeform() {

    document.documentElement.style.setProperty('--form-display', 'none');

  }
 

const Request_form = (report, sec_id) => {

    const api_endpoint = 'https://pwpapi.carraighill.eu/rd/'
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (e, sample_id, sec_id) => {

      console.log(sec_id)
      sec_id = 'fffc2053-08f8-41ff-90a4-bd571cdd8f88'
      
        e.preventDefault();
        try {
          const response = await axios.post(api_endpoint, {
            sec_id,
            report,
            name,
            company,
            email,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': '*/*',
              //'Access-Control-Allow-Origin': '*',
              // 'Authorization': token,
            },});
          
          setName('');
          setCompany('');
          setEmail('');

          console.log(response.data.url)
          
          window.open(response.data.url, "_blank");

        } catch (error) {
          console.error('Error submitting form:', error);
        }


      };

    let form = 
        <div class="form">
            <div class="close" onClick={() => closeform()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            
            </div>
            <div class="container-text">
                <h3>Carraighill provides investment insights and recommendations to clients. All of the reports available here are dated and no longer constitute investment advice.</h3>
                <form onSubmit={event => handleSubmit(event, report)}>
                    <div>
                    <input
                        type="text"
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    </div>
                    <div>
                    {/* <label htmlFor="company">Company: </label> */}
                    <input
                        type="text"
                        id="company"
                        placeholder="Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                    />
                    </div>
                    <div>
                    {/* <label htmlFor="email">Email: </label> */}
                    <input
                        type="email"
                        id="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </div>
                    <button type="submit"onClick={() => closeform()}>Submit</button>
                </form>
            </div>
        </div> 
    return (
        form
    )

}


export default Request_form;
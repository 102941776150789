import logo from './logo.svg';
import './App.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Preload from './preloader/preloader.js';
import React, { useState, useEffect, useRef } from 'react'


import Footer from './footer/footer.js'
import Top_menu from './top_menu/top_menu.js';
import Sample from './sample/sample.js';

import {v4 as uuidv4} from 'uuid';
let sec_id = uuidv4();



// import { useScrollData } from "scroll-data-hook";
// import { useCarousel } from "use-carousel-hook";


// const dotenv = require('dotenv').config();





function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function UseWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function genHexagon(x, y, size, id) {
  let element = []
  let text = id
  let divClass = 'hide'
  let hexaClass = 'hexa'
  let hexShowMain = [ 'b11', 'b13', 
                  'c10', 'c12', 'c14', 
                  'd11', 'd13'
                ]

  let hexShowSec = ['b9', 'b15', 
                  'c8', 'c16', 
                  'd9', 'd15',
                  ]

  if (hexShowMain.includes(id)) {
    divClass = 'show' 
  }

  if (hexShowSec.includes(id)) {
    divClass = 'shade'
  }

  if (id === 'b15') {
    text = "Inflation & Money Supply Growth Across Geographies "
    divClass = 'hexa-system' 
    
  } else if (id === 'c8') {
    text = "The Industrial Structure "
    divClass = 'hexa-system' 

    
  } else if (id === 'c12') {
    text = "Company Recommendations"
    hexaClass = "hexa cf"
    
  } else if (id === 'b9') {
    text = "The Population & Labour Market "
    divClass = 'hexa-system' 

    
  } else if (id === 'd9') {
    text = "The Politics and Social Drivers "
    divClass = 'hexa-system' 

    
  } else if (id === 'c16') {
    text = "Household & Non-Financial Corporate Cash Flow"
    divClass = 'hexa-system' 

  } 
    
  else if (id === 'd15') {
    text = "Total Economy Debt and Interest "
    divClass = 'hexa-system' 
   
  } 
    
  else if (id === 'b11') {
    text = "The Evolution of Bank Net Interest Income"
    // divClass = 'hexa-system' 
   
  } 
    
  else if (id === 'b11') {
    text = "Asset Management Flows and Fees"
    // divClass = 'hexa-system' 
   
  } 
    
  else if (id === 'c10') {
    text = "Household & Corporate Interest Coverage Ratios"
    // divClass = 'hexa-system' 
   
  }  
    
  else if (id === 'd11') {
    text = "Asset Management Flows"
    // divClass = 'hexa-system' 
   
  }  
    
  else if (id === 'b13') {
    text = "Household Discretionary Spending Power (HDSM™)"
    // divClass = 'hexa-system' 
   
  }  
    
  else if (id === 'c14') {
    text = "The Profit Drivers Across Companies & Sectors"
    // divClass = 'hexa-system' 
   
  } 
    
  else if (id === 'd13') {
    text = "The evolution of monetary policy"
    // divClass = 'hexa-system' 
   
  }  else {
    text = ""
  }

  if (id === 'c12') {
    element =
    <div>
      <div class={divClass + "line company-lines"} style={{position:'absolute', top:y - size, left:x - size, width:size*4, height:size*3, zIndex:'-10'}}>
      <div class="content">
          <div class="content__container">
            <p class="content__container__text">
              Hello
            </p>
            
            <ul class="content__container__list">
              <li class="content__container__list__item">world !</li>
              <li class="content__container__list__item">bob !</li>
              <li class="content__container__list__item">users !</li>
              <li class="content__container__list__item">everybody !</li>
            </ul>
          </div>
        </div>
        <svg width={size * 4 + "px"} height={ size * 3 +"px"}>
        
          {/* <circle key='a1' cx='100' cy='520' r='10' fill="black" />
          <text x='120' y='522' fontSize='1.5rem' dominantBaseline="middle">
              Meetings with management
          </text>
          <circle key='a2' cx='350' cy='450' r='10' fill="black" />
          <text x='370' y='452' fontSize='1.5rem' dominantBaseline="middle">
              Model build
          </text>
          <circle key='a2' cx='440' cy='380' r='10' fill="black" />
          <text x='460' y='382' fontSize='1.5rem' dominantBaseline="middle">
            Fundamental Forecasts
          </text>
          <circle key='a2' cx='480' cy='310' r='10' fill="black" />
          <text x='500' y='312' fontSize='1.5rem' dominantBaseline="middle">
            Long and Short Investment Recommendations 
          </text>
          <circle key='a2' cx='450' cy='250' r='10' fill="black" />
          <text x='470' y='250' fontSize='1.5rem' dominantBaseline="middle">
            50+ companies covered  
          </text>
         */}



        </svg>

      </div>
      <div class={divClass} style={{position:'absolute', top:y, left:x}}>
        <svg width={size + "px"} height={ size +"px"} viewBox="0 0 100 100" preserveAspectRatio="none" version="1.1" className={hexaClass + " hexa-" + id} xmlns="http://www.w3.org/2000/svg">    
            <polygon class={divClass + " z hexa-all-" + id} points="50 0, 100 25, 100 75, 50 100, 0 75, 0 25"/>
            <polygon class={"hexa a hexa-" + id} points="50 0, 100 25"/>
            <polygon class={"hexa b hexa-" + id} points="100 25, 100 75"/>
            <polygon class={"hexa c hexa-" + id} points="100 75, 50 100"/>
            <polygon class={"hexa d hexa-" + id} points="50 100, 0 75"/>
            <polygon class={"hexa e hexa-" + id} points="0 75, 0 25"/>
            <polygon class={"hexa f hexa-" + id} points="0 25, 50 0"/>
            
            <foreignObject x="4" y="20" width="92" height="80">
              <p id={"hexa-text hexa-" + id} class={"hexa-text hexa-" + id} x="20" y="40" >{ text }</p>
            </foreignObject>    
        </svg >
      
      </div>
    </div>

  } else {
    element = <div class={divClass} style={{position:'absolute', top:y, left:x}}>
      <svg width={size + "px"} height={ size +"px"} viewBox="0 0 100 100" preserveAspectRatio="none" version="1.1" className={hexaClass + " hexa-" + id} xmlns="http://www.w3.org/2000/svg">    
          <polygon class={divClass + " z hexa-all-" + id} points="50 0, 100 25, 100 75, 50 100, 0 75, 0 25"/>
          <polygon class={"hexa a hexa-" + id} points="50 0, 100 25"/>
          <polygon class={"hexa b hexa-" + id} points="100 25, 100 75"/>
          <polygon class={"hexa c hexa-" + id} points="100 75, 50 100"/>
          <polygon class={"hexa d hexa-" + id} points="50 100, 0 75"/>
          <polygon class={"hexa e hexa-" + id} points="0 75, 0 25"/>
          <polygon class={"hexa f hexa-" + id} points="0 25, 50 0"/>
          <foreignObject x="4" y="20" width="92" height="80">
            <h2 id={"hexa-text hexa-" + id} class={"hexa-text hexa-" + id} x="20" y="40" >{ text }</h2>
          </foreignObject>    
      </svg>
      </div>
  }


  
  return element
}



function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate the delay of loading content
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);


  const { height, width } = UseWindowDimensions();
  // const { position } = useScrollData();
  let size = width / 10; // 180
  // let viewportHeight = window.innerHeight;
  // console.log(viewportHeight)
  let mobile_hide = ' '

  var screen_type = 'D'
  var slides = 2    
  var sample_turkish = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/Dissecting+Turkey+4.png'
  var sample_uk = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/UK+Banks+Top+Line+Drivers.png'

  if (width < 600) {
    screen_type = 'M'
    size = 100;
    var floating_menu = 'floating_menu_hide';
    slides = 1
    sample_turkish = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/Turkish_mobile_2.png'
    sample_uk = 'https://cdvp-open.s3.eu-west-1.amazonaws.com/UK.png'
    mobile_hide = 'mobile_hide'
 
  } 

  var x = width * -0.16;
  var y = 50;


  // const intervalID = setInterval(myCallback, 510);
 
  // function myCallback() {
  //   var hexs = ['a0', 'a2', 'a4', 'a6', 'a8', 'a10', 'a12', 'a14']
  //   var hex = hexs[Math.floor(Math.random() * hexs.length)];



  //   x = x + 1;

    



  //   // document.documentElement.style.setProperty('-hexa-' + hex + '-opacity', '1');
  //   // Your code here
  //   // Parameters are purely optional.
  //   // console.log('-hexa-' + hex + '-opacity');
  //   // console.log(b);
  // }

  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {

    const onScroll = (e) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollValue]);

  let position = scrollValue;
  // console.log(position);


  // const handleScroll = () => {
  //     position = window.scrollY; // => scroll position
  //     console.log(position);
  // };useEffect(() => {
  //   handleScroll();
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  //   });






  if (position <= height - (height * 0.3)) {
    document.documentElement.style.setProperty('--hexa-stroke-opacity', '0.2');
    document.documentElement.style.setProperty('--hexa-background-color', 'rgba(9,28,42,1)');
    document.documentElement.style.setProperty('--hexa-opacity', '0.25');    
    document.documentElement.style.setProperty('--hexa-hide-opacity', '1');        
    document.documentElement.style.setProperty('--hexa-shade-opacity', '1');       
    document.documentElement.style.setProperty('--hexa-text-opacity', '0');        
    document.documentElement.style.setProperty('--hexa-cf-fill', 'rgba(9,28,42,1)');   
    document.documentElement.style.setProperty('--menu-background-color', 'rgb(6, 13, 22)');
    document.documentElement.style.setProperty('--hexa-cf-opacity', '0.25');    
    document.documentElement.style.setProperty('--hexa-system-opacity', '1');    
    document.documentElement.style.setProperty('--floating-menu-color', '#fff');         
    document.documentElement.style.setProperty('--understanding-opacity', '0');
    document.documentElement.style.setProperty('--menu-hex-display', 'inline');
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'block');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');            
  }

  if (position >= 300) {    
    document.documentElement.style.setProperty('--floating-menu-color', 'rgba(9,28,42,1)');    
    document.documentElement.style.setProperty('--floating-menu-hex-a-color:', 'rgba(9,28,42,1)'); 
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'block');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');     
  }



  if (position > height - (height * 0.3)) {
    document.documentElement.style.setProperty('--hexa-stroke-opacity', '0');
    document.documentElement.style.setProperty('--hexa-fill', 'rgba(9,28,42,1)');
    document.documentElement.style.setProperty('--hexa-opacity', '1');    
    document.documentElement.style.setProperty('--hexa-hide-opacity', '0.3');        
    document.documentElement.style.setProperty('--hexa-shade-opacity', '0.8');       
    document.documentElement.style.setProperty('--hexa-text-opacity', '1');        
    document.documentElement.style.setProperty('--hexa-cf-fill', 'rgb(147, 125, 62)'); 
    document.documentElement.style.setProperty('--menu-background-color', 'rgba(9,28,42,1)');
    document.documentElement.style.setProperty('--hexa-cf-opacity', '1');        
    document.documentElement.style.setProperty('--hexa-system-opacity', '1');        
    document.documentElement.style.setProperty('--understanding-opacity', '1');         
    document.documentElement.style.setProperty('--menu-hex-display', 'none');   
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'block');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');             
  }

  if (position >= (2 * height) - (height * 0.3)) {           
    document.documentElement.style.setProperty('--hexa-cf-opacity', '0.3');        
    document.documentElement.style.setProperty('--hexa-shade-opacity', '0.2');    
    document.documentElement.style.setProperty('--hexa-system-opacity', '1');    
    document.documentElement.style.setProperty('--hexa-hide-opacity', '0.2');       
    document.documentElement.style.setProperty('--hexa-out-text-opacity', '0'); 
    document.documentElement.style.setProperty('--hexa-cf-left', '0px'); 
    document.documentElement.style.setProperty('--company-list-opacity', '0'); 
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'block');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');       

  }

  if (position >= (3 * height) - (height * 0.3)) {            
    document.documentElement.style.setProperty('--hexa-cf-opacity', '0.8');
    document.documentElement.style.setProperty('--hexa-cf-left', '-300px');
    document.documentElement.style.setProperty('--hexa-system-opacity', '0');        
    document.documentElement.style.setProperty('--hexa-shade-opacity', '0');       
    document.documentElement.style.setProperty('--hexa-opacity', '0');
    document.documentElement.style.setProperty('--company-list-opacity', '1');        
    document.documentElement.style.setProperty('--understanding-opacity', '0'); 
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'block');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');               
    

  }

  if (position >= (3 * height) + (height * 0.15)) {
    document.documentElement.style.setProperty('--hexa-cf-opacity', '0');
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'block');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');       
  }

  if (position >= (4 * height) + (height * 0.15)) {
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'block');       
  }

  if (position >= (5 * height) + (height * 0.15)) {
    document.documentElement.style.setProperty('--floating-menu-hex-a-display', 'none');
    document.documentElement.style.setProperty('--floating-menu-hex-b-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-c-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-d-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-e-display', 'none');            
    document.documentElement.style.setProperty('--floating-menu-hex-f-display', 'none');       
  }




  const home = useRef(null);
  const approach = useRef(null);
  const monthly = useRef(null);
  const company = useRef(null);
  const sample = useRef(null);
  const insights = useRef(null);
  

  const scrollTo = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  async function showform() {
    
    // document.documentElement.style.setProperty('--form-width', '50%');
    // await new Promise(resolve => setTimeout(resolve, 250));
    document.documentElement.style.setProperty('--form-display', 'block');
    
        
    

  }
  async function closeform() {
    // document.documentElement.style.setProperty('--form-width', '0');
    // await new Promise(resolve => setTimeout(resolve, 260));
    document.documentElement.style.setProperty('--form-display', 'none');

  }
 
 
  const hexs = []
  
  let wCount = Math.floor( width / (size * 0.55) )


  for (let i = 0; i < wCount; i++) {
    const interval = i * 0.65

    
    if (i % 2 === 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (0 * size)  , size, "a" + i ))
    }

    if (i % 2 !== 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (1 * size)  , size, "b" + i ))
    }

    if (i % 2 === 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (2 * size)  , size, "c" + i))
    }

    if (i % 2 !== 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (3 * size)  , size, "d" + i))
    }

    if (i % 2 === 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (4 * size)  , size, "e" + i))
    }

    if (i % 2 !== 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (5 * size)  , size, "f" + i))
    }

    if (i % 2 === 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (6 * size)  , size, "g" + i))
    }

    if (i % 2 !== 0) {
      hexs.push( genHexagon(x + (interval * size)  , y + (7 * size)  , size, "h" + i))
    }
 }

//  const [ip,setIP] = useState('');
//  console.log('IP is:')
//  console.log(ip)

 let top_menu = Top_menu(screen_type)
 let footer = Footer(screen_type)
 let sample_reports = Sample(screen_type, sec_id)

//  console.log(footer)

  
  return (
    <div className='x2'>
      {
        isLoading ? <Preload /> :

        <div class="App">
          <script src="https://d3js.org/d3.v6.js"></script>
          <script type="module" src="./app.js"></script>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,600;1,300&display=swap');
          </style>

            {top_menu}



            <div id="hexs">
              { hexs }
    
            </div>
            <div className='understanding ' id={mobile_hide} >
                <h1>Understand</h1>
            </div>
            <div id="hero" ref={home}>

            </div>
            <div id="floating_menu" class={floating_menu}>
              <ul>
                <li>
                  <div className='floating_menu_container'>
                    <div className='floating_menu_child_a'>
                      <svg
                        className='floating_menu_hex'
                        width="15"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon className='hex_a'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                    </div>
                    <div className='floating_menu_child_b'>
                      <span onClick={() => scrollTo(home)}>Home</span>
                    </div>
                  </div>
                </li>
                <li>
                <div className='floating_menu_container'>
                    <div className='floating_menu_child_a'>
                      <svg
                        className='floating_menu_hex'
                        width="15"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon className='hex_b'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                    </div>
                    <div className='floating_menu_child_b'>
                      <span onClick={() => scrollTo(approach)}>Approach</span>
                    </div>
                  </div>
                </li>
                <li>
                <div className='floating_menu_container'>
                  <div className='floating_menu_child_a'>
                    <svg
                      className='floating_menu_hex'
                      width="15"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <polygon className='hex_c'
                        points="50,5 90,30 90,70 50,95 10,70 10,30"
                        // stroke='rgba(9,28,42,1)'
                        // fill="rgba(9,28,42,1)"
                      />
                    </svg>
                    </div>
                    <div className='floating_menu_child_b'>
                      <span onClick={() => scrollTo(monthly)}>Monthly Data</span>
                    </div>
                  </div>
                </li>
                <li>
                <div className='floating_menu_container'>
                  <div className='floating_menu_child_a'>
                    <svg
                      className='floating_menu_hex'
                      width="15"
                      height="15"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <polygon className='hex_d'
                        points="50,5 90,30 90,70 50,95 10,70 10,30"
                        // stroke='rgba(9,28,42,1)'
                        // fill="rgba(9,28,42,1)"
                      />
                    </svg>
                  </div>
                  <div className='floating_menu_child_b'>
                    <span onClick={() => scrollTo(company)}>Company Recommendations</span>
                  </div>
                </div>
                  
                </li>
                <li>
                  <div className='floating_menu_container'>
                    <div className='floating_menu_child_a'>
                      <svg
                        className='floating_menu_hex'
                        width="15"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon className='hex_e'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                    </div>
                    <div className='floating_menu_child_b'>
                      <span onClick={() => scrollTo(sample)}>Sample Reports</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='floating_menu_container'>
                    <div className='floating_menu_child_a'>
                      <svg
                        className='floating_menu_hex'
                        width="15"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon className='hex_f'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                    </div>
                    <div className='floating_menu_child_b'>
                      <span onClick={() => scrollTo(insights)}>Insights</span>
                    </div>
                  </div>
                </li>
              </ul>
              

            </div>
           



            <div id="heroMessage">
              <h1>Insight Distilled</h1>
              <svg class="hero-line" viewBox="0 0 100 10" xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="5" x2="100" y2="5"/>
              </svg>
              <h2>A research firm focussing on alpha generating insight in the financial sector.</h2>
              <h2>Our approach is exhaustive and unique.</h2>
              <h2>We service a global client base.</h2>
              <h2>We are independent.</h2>
            </div>
            <div id="approach" class="approach" ref={approach}>
            

              <div class="approach_intro">
                <div class="text">
                  <h1>The Carraighill Approach</h1>
                  <h2><strong>1. To understand the environment within which our companies operate.</strong> For this, we distil large quantities of complex financial system data into clear analytics and insight.</h2>
                  <h2><strong>2. To assess the bottom-up fundamental stock price drivers across each company.</strong></h2>
                  <h2>Our geographies extend to Europe, UK, Scandinavia, Eastern Europe (including Turkey), Brazil and Mexico.</h2>
                </div>
                
                {/* <div class="vert_line">
                  <svg class="approach-line" viewBox="0 0 100 1000" xmlns="http://www.w3.org/2000/svg">
                    <line x1="50" y1="0" x2="50" y2="600"/>
                  </svg>
                </div> */}
              </div>
            </div>
            <div id="monthly_data" class="approach" ref={monthly}>
              <div class="approach_intro monthly">
                <div class="text">
                  <h1>Monthly Data Insights</h1>
                  <h2>We deconstruct large volumes of monthly and weekly financial data into clear insight. This allows our clients to better understand the environment within which our companies operate.</h2>
                  <h2><strong>Many of these calculations are proprietary to Carraighill and have been developed over years of analysis.</strong></h2>
                  <h2>A series of monthly or quarterly reports allows you to keep abreast of current and evolving investment trends.</h2>
                </div>
                {/* <div class="vert_line">
                  <svg class="approach-line" viewBox="0 0 100 1000" xmlns="http://www.w3.org/2000/svg">
                    <line x1="50" y1="0" x2="50" y2="600"/>
                  </svg>
                </div> */}
              </div>
              

            </div>
            <div id="company" class="approach" ref={company}>
              <div class="approach_intro">
                <div class="text">
                  <h1>Company Recommendations</h1>
                  <h2>This is a bottom-up approach. It involves conversations with management, model build and an assessment of valuation. Each recommendation seeks to offer 50% upside or downside.</h2>
                  <h2>Our company coverage extends to:</h2>
                  <ul className='company-items'>
                    <li>
                      <span>Banks</span>
                    </li>
                    <li>
                      <span>Payments</span>
                    </li>
                    <li>
                      <span>Diversified Financials</span>
                    </li>
                    <li>
                      <span>Housebuilders</span>
                    </li>
                    <li>
                      <span>REITs</span>
                    </li>
                  </ul>
                </div>
                  <ul className='company-list' id={mobile_hide}>
                    <li className='a'>
                      <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon class='cp-hex'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                      <span>Model Build</span>
                    </li>
                    <li className='b'>
                      <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon class='cp-hex'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                      <span>Fundamental Forecasts</span>
                    </li>
                    <li className='c'>
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon class='cp-hex'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                      <span>Meetings with Management</span>
                    </li>
                    <li className='d'>
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon class='cp-hex'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                      <span>Long and Short Ideas</span>
                    </li>
                    <li className='e'>
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon class='cp-hex'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                      <span>50+ Companies Covered</span>
                    </li>
                    <li className='f'>
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                      >
                        <polygon class='cp-hex'
                          points="50,5 90,30 90,70 50,95 10,70 10,30"
                          // stroke='rgba(9,28,42,1)'
                          // fill="rgba(9,28,42,1)"
                        />
                      </svg>
                      <span>Target 50% Upside or Downside</span>
                    </li>
                  </ul>    
                  
                
                
                {/* <div class="vert_line">
                  <svg class="approach-line" viewBox="0 0 100 1000" xmlns="http://www.w3.org/2000/svg">
                  <line x1="50" y1="0" x2="50" y2="600"/>
                  </svg>
                </div> */}
              </div>
              
            </div>

            <div id="sample" class="sample" ref={sample}>
              { sample_reports }
            </div>      
            <div id="insights" class="insights" ref={insights}>
              <div class="insights-title">
                <a href='https://insights.carraighill.com/insights/'>
                  <h1>Carraighill Insights</h1>
                </a>
              </div>
              <div class="insights-carousel">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={slides}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
              >
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/banks-the-yield-curve/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2024/09/iStock-1866700720-scaled.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/banks-the-yield-curve/'>
                      <h3>Banks & the yield curve</h3>
                    </a>
                    <p>What do banks do and how do they make money? The simple answer to this question is that banks borrow short and lend long. What does this mean? Essentially, banks take in short-term deposits and lend this money out as long-term loans to households, companies and the government.</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/banks-the-yield-curve/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/uk-house-prices-why-they-have-not-fallen-and-the-factors-that-influence-the-direction-from-here/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2021/03/UK-Housebuilder.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/uk-house-prices-why-they-have-not-fallen-and-the-factors-that-influence-the-direction-from-here/'>
                      <h3>UK House Prices: Why they have not fallen and the factors that influence the direction from here </h3>
                    </a>
                    <p>Many people had expected UK house prices to decline as interest rates rose. However, we always try to understand the multitude of factors that influence the demand and supply side of the equation to arrive at a balanced assessment of the outlook for house prices rather than focus on one variable. The evolution of this asset class is important for...</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/uk-house-prices-why-they-have-not-fallen-and-the-factors-that-influence-the-direction-from-here/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/how-we-are-thinking-about-european-banks/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2022/12/European-Banks.png' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/how-we-are-thinking-about-european-banks/'>
                      <h3>How we are thinking about European Banks</h3>
                    </a>
                    <p>Since the last post  we wrote on European Banks (August 2022), the SX7E European Banks index is up c.55% and recently reached a six year high. It is among the best preforming sectors in Europe this year.</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-we-are-thinking-about-european-banks/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/uk-banks-pricing-power-returning-but-qt-and-balance-sheet-size-is-a-concern/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2021/03/UK-banks-swap-income.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/uk-banks-pricing-power-returning-but-qt-and-balance-sheet-size-is-a-concern/'>
                      <h3>UK Banks’ Pricing Power Returning, but QT and Balance Sheet Size is a Concern</h3>
                    </a>
                    <p>In recent months, the data we follow in the UK has indicated a return of the domestic banks’ pricing power. Whereas the customer spread (loan income less deposit expense) now appears to have peaked throughout the Eurozone, it has begun to grow in the UK.</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/uk-banks-pricing-power-returning-but-qt-and-balance-sheet-size-is-a-concern/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/will-investment-spending-collapse-in-2024-and-what-does-it-mean/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2023/12/iStock-1154906770-scaled.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/will-investment-spending-collapse-in-2024-and-what-does-it-mean/'>
                      <h3>Will Investment Spending Collapse in 2024 and What Does It Mean?</h3>
                    </a>
                    <p>Over the last few months, we have been closely watching the monthly loan data in Europe, the UK, Scandinavia and Emerging Markets. It continues to suggest that investment spending will fall in the new year. This short blog looks at this issue and seeks to understand the underlying drivers of this change.</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/will-investment-spending-collapse-in-2024-and-what-does-it-mean/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/adyen-nexi-and-worldline-where-do-they-stand-in-europes-payments-universe/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2021/05/European-Asset-Managers.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/adyen-nexi-and-worldline-where-do-they-stand-in-europes-payments-universe/'>
                      <h3>Adyen, Nexi and Worldline – Where do they Stand in Europe’s Payments Universe?</h3>
                    </a>
                    <p>At Carraighill, we conduct ongoing analysis of three European payments companies: Adyen, Nexi and Worldline. These are the major publicly listed players headquartered in Europe.</p>
                    <p>The European market is highly fragmented and despite extensive M&A activity in recent years, traditional banks still process most of payment volume...</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/adyen-nexi-and-worldline-where-do-they-stand-in-europes-payments-universe/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/has-political-pressure-resulted-in-uk-monetary-policy-failure/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2021/03/UK-banks-swap-income.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/has-political-pressure-resulted-in-uk-monetary-policy-failure/'>
                      <h3>Has Political Pressure Resulted in UK Monetary Policy Failure?</h3>
                    </a>
                    <p>The banking system data we cover from the UK brings into question the Bank of England’s ability to rein in inflation given pressures from the government to pass on higher rates to depositors. This short blog seeks to explain this, and why it is important for investment decisions in UK companies.</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/has-political-pressure-resulted-in-uk-monetary-policy-failure/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/uk-corporate-interest-coverage-ratios-are-also-set-to-collapse-does-it-matter/'>
                    
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2022/10/Is-the-UK-in-Crisis.png' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/uk-corporate-interest-coverage-ratios-are-also-set-to-collapse-does-it-matter/'>
                      <h3>UK Corporate Interest Coverage Ratios Are Also Set to Collapse. Does it Matter?</h3>
                    </a>
                    <p>We previously outlined that European non-financial corporate (NFC) interest coverage ratios are set to fall sharply in the coming years if current rates persist. The UK is no different. This short piece seeks to explore some of the data points around this emerging risk and as importantly, who is more likely to lose money on these loans? ...</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/uk-corporate-interest-coverage-ratios-are-also-set-to-collapse-does-it-matter/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/falling-money-supply-and-the-velocity-of-money-a-primer-given-the-markets-renewed-obsession-with-falling-m3/'>
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2023/08/iStock-1348057954-scaled.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/falling-money-supply-and-the-velocity-of-money-a-primer-given-the-markets-renewed-obsession-with-falling-m3/'>
                      <h3>Falling Money Supply and the Velocity of Money – A Primer Given the Markets Renewed Obsession with Falling M3</h3>
                    </a>
                    <p>Milton Friedman famously claimed that inflation is always and everywhere a monetary phenomenon. Supporters of this theory contend that in the long run, the supply of money is the key determinant of price levels and that the rate of change in the money supply likewise explains the rate of change in the price level, i.e. inflation.</p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/falling-money-supply-and-the-velocity-of-money-a-primer-given-the-markets-renewed-obsession-with-falling-m3/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <a href='https://insights.carraighill.com/corporate-interest-coverage-ratios-are-set-to-collapse/'>
                    <img className='insights-main-img' src='https://insights.carraighill.com/wp-content/uploads/2021/07/shutterstock_1982542727.jpg' alt='banks' height={200}></img>
                  </a>
                  <div class='insights-short-text'>
                    <a href='https://insights.carraighill.com/corporate-interest-coverage-ratios-are-set-to-collapse/'>
                      <h3>Corporate Interest Coverage Ratios Are Set to Collapse. Does it Matter?</h3>
                    </a>
                    <p>The Euro Area has spent most of the past two decades in an era of low or negative interest rates, supported by quantitative easing. Non-financial corporates have been incentivised to increase their debt levels, while investors have been pushed from government bonds into corporate bonds in a search for yield. This led to three important outcomes... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/corporate-interest-coverage-ratios-are-set-to-collapse/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2022/12/European-Banks.png' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>How the Level of Interest Paid in an Economy Influences European Banks Net Interest Income and Provision Outlook</h3>
                    <p>Since 2008, total household debt has risen by 40% in absolute euro terms in Germany. In France, it is +80%,  the Netherlands (+30%), Belgium (+90%) and Austria (+42%). It is down by 36% and 22% in Ireland and Spain, respectively. The leverage mix has also changed in every country... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/05/iStock-1255937750.jpg' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>What is the Deposit Beta and Why is it Important for European Banks?</h3>
                    <p>Banks earn interest on their assets (primarily loans), but also pay interest on their liabilities (primarily deposits). As interest rates rise, European banks manage the difference between these two items. This difference is known as the customer spread (banks also make money in other ways, but this is the primary driver of their earnings)... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/02/iStock-1279168957.jpg' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>It’s getting ugly in Brazil as warning signs emerge </h3>
                    <p>Between 2016 and 2021, the number of Brazilians in Ireland rose from 13,600 to 70,000, according to an estimate by the Brazilian Embassy. This is an astonishing number that continues to grow year-on-year. Indeed, there was a recent survey which suggested that 76% of young people in Brazil wanted to emigrate. Why are they leaving?... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                {/*<SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/02/The-Unstoppable-European-Consumer.png' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>The Unstoppable European Consumer?</h3>
                    <p>Last year was supposed to be the year Europe stopped spending. The thinking went that the surge in prices would leave us all unable to afford our existing way of life. Spending on just about everything except the essentials would grind to a halt. A recession would follow with job losses everywhere and a collapse in discretionary consumer share prices. ... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/02/The-Unstoppable-European-Consumer.png' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>How the Level of Interest Paid in an Economy Influences European Banks Net Interest Income and Provision Outlook</h3>
                    <p>Since 2008, total household debt has risen by 40% in absolute euro terms in Germany. In France, it is +80%,  the Netherlands (+30%), Belgium (+90%) and Austria (+42%). It is down by 36% and 22% in Ireland and Spain, respectively. The leverage mix has also changed in every country... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/02/The-Unstoppable-European-Consumer.png' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>How the Level of Interest Paid in an Economy Influences European Banks Net Interest Income and Provision Outlook</h3>
                    <p>Since 2008, total household debt has risen by 40% in absolute euro terms in Germany. In France, it is +80%,  the Netherlands (+30%), Belgium (+90%) and Austria (+42%). It is down by 36% and 22% in Ireland and Spain, respectively. The leverage mix has also changed in every country... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/02/The-Unstoppable-European-Consumer.png' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>How the Level of Interest Paid in an Economy Influences European Banks Net Interest Income and Provision Outlook</h3>
                    <p>Since 2008, total household debt has risen by 40% in absolute euro terms in Germany. In France, it is +80%,  the Netherlands (+30%), Belgium (+90%) and Austria (+42%). It is down by 36% and 22% in Ireland and Spain, respectively. The leverage mix has also changed in every country... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <img src='https://insights.carraighill.com/wp-content/uploads/2023/02/The-Unstoppable-European-Consumer.png' alt='banks' height={200}></img>
                  <div class='insights-short-text'>
                    <h3>How the Level of Interest Paid in an Economy Influences European Banks Net Interest Income and Provision Outlook</h3>
                    <p>Since 2008, total household debt has risen by 40% in absolute euro terms in Germany. In France, it is +80%,  the Netherlands (+30%), Belgium (+90%) and Austria (+42%). It is down by 36% and 22% in Ireland and Spain, respectively. The leverage mix has also changed in every country... </p>
                  </div>
                  <div class='insights-read-more'>
                    <a href='https://insights.carraighill.com/how-the-level-of-interest-paid-in-an-economy-influences-european-banks-net-interest-income-and-provision-outlook/'>Read more</a>
                  </div>
                </SwiperSlide> */}
              </Swiper>

              </div>

                

            </div>
            { footer }
            

        </div>
      }
    </div>
  );
}

export default App;

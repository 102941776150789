import React from 'react';
import './preloader.css'


function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

const Preload = ({ isVisible }) => {

  // Show preload 2
  async function showPreload() {
    await delay(500);
    document.documentElement.style.setProperty('--hexagon-opacity', '1');
    await delay(900);
    document.documentElement.style.setProperty('--hexagon-opacity', '0');
    await delay(500);
  };


  if (typeof window === 'object') {
    showPreload();
  }

  return (
    <div className="loadingMain">
      <div>
        <svg
          width="150"
          height="150"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <polygon className='pl-hexagon1'
            points="50,5 90,30"
            // stroke='rgba(9,28,42,1)'
            // fill="rgba(9,28,42,1)"
          />
          <polygon className='pl-hexagon2'
          points="90,30 90,70"
          // stroke='rgba(9,28,42,1)'
          // fill="rgba(9,28,42,1)"
          />
          <polygon className='pl-hexagon3'
          points="90,70 50,95"
          // stroke='rgba(9,28,42,1)'
          // fill="rgba(9,28,42,1)"
          />
          <polygon className='pl-hexagon4'
          points="50,95 10,70"
          // stroke='rgba(9,28,42,1)'
          // fill="rgba(9,28,42,1)"
          />
          <polygon className='pl-hexagon5'
          points="10,70 10,30"
          // stroke='rgba(9,28,42,1)'
          // fill="rgba(9,28,42,1)"
          /> 
          <polygon className='pl-hexagon6'
          points="10,30 50,5"
          // stroke='rgba(9,28,42,1)'
          // fill="rgba(9,28,42,1)"
          />
          <polygon className='pl-hexagon'
            points="50,5 90,30 90,70 50,95 10,70 10,30"
            // stroke='rgba(9,28,42,1)'
            // fill="rgba(9,28,42,1)"
          />

        </svg>
      </div>
    </div>
  );
};



export default Preload;
